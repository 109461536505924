<template>
    <div style="opacity: 1">
        <v-col align="center" justify="center">
        <v-icon class="mb-2" :color="iconColor" size="60">{{icon}}</v-icon>

       <div class="mt-0 mb-3"  style="line-height: 1.2; font-size: 16px">{{text}}</div>
        <TextButton v-show="button" :icon="buttonIcon" v-on:click="$emit('click')" class="mt-1" buttonColor="primary" dense :text="buttonText"/>

    </v-col>
    </div>
</template>

<script>
import TextButton from "../button/TextButton.vue";


export default {
    props: {
        text: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        button: {
            type: Boolean,
            default: false
        },
        buttonIcon: {
            type: String,
            default: undefined
        },
        buttonText: {
            type: String,
            default: '',
        },

        iconColor: {
          type: String,
          default: 'primary'
        }

    },


    components: {
        TextButton
    }

}

</script>
