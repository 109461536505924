<template>
  <div>
    <v-row class="ma-0" align="center">
      <TextInput
        class="mr-2"
        v-show="!roleOnly"
        v-on:change="userMail = $event"
        :value="userMail"
        :placeholder="$t('helper.userInvite.mail')"
      />

      <div :style="disabled || loading ? 'pointer-events: none!important' : ''">
        <v-menu v-model="menu" :disabled="disabled" nudge-top="2">
          <template v-slot:activator="{on, attrs}">
            <div class="role-selector pl-2" v-bind="attrs" v-on="on">
              <v-hover :disabled="disabled || loading" v-slot="{hover}">
                <v-row class="ma-0" style="padding-top: 10px" align="center">
                  <span
                    class="pl-5 font-weight-bold"
                    style="
                      font-size: 15px;
                      user-select: none;
                      color: rgba(0, 0, 0, 0.8);
                    "
                    >{{ userTypes[selectedUserRole].descr }}</span
                  >
                  <v-spacer />
                  <div style="height: 20px" v-if="loading">
                    <v-progress-circular
                      indeterminate
                      width="2"
                      size="16"
                      color="primary"
                      class="mr-4 mb-2"
                    ></v-progress-circular>
                  </div>
                  <v-icon
                    v-else
                    v-show="!disabled"
                    size="20"
                    class="mr-3"
                    :class="hover ? 'primary--text' : ''"
                    >mdi-chevron-down</v-icon
                  >
                </v-row>
              </v-hover>
            </div>
          </template>
          <v-list nav width="200">
            <v-list-item-group v-model="selectedUserRole">
              <v-list-item
                :style="
                  selectedUserRole == index
                    ? 'cursor: default!important; pointer-events: none!important'
                    : ''
                "
                dense
                :ripple="false"
                v-for="(type, index) in userTypes"
                :key="index"
              >
                <v-list-item-title
                  class="ml-1"
                  :class="
                    selectedUserRole == index
                      ? 'primary--text font-weight-bold'
                      : ''
                  "
                  style="font-size: 15px"
                  >{{ type.descr }}</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </v-row>
  </div>
</template>

<script>
import TextInput from '@components/text/TextInput.vue';

export default {
  name: 'UserProjectInviteInput',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    roleOnly: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
    },
    role: {
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.role != null)
      this.selectedUserRole = this.role == 10 ? 3 : this.role;
  },
  data() {
    return {
      menu: false,
      userMail: String(),
      selectedUserRole: 0,
      userTypes: [
        {
          type: 'VIEWER',
          id: 0,
          descr: this.$t('helper.userInvite.role.viewer'),
        },
        {
          type: 'EDITOR',
          id: 1,
          descr: this.$t('helper.userInvite.role.editor'),
        },
        {type: 'OWNER', id: 2, descr: this.$t('helper.userInvite.role.owner')},
        {
          type: 'DASHBOARD_ONLY',
          id: 10,
          descr: this.$t('helper.userInvite.role.dashboardonly'),
        },
      ],
    };
  },
  watch: {
    role() {
      if (this.role != null)
        this.selectedUserRole = this.role == 10 ? 3 : this.role;
    },
    selectedUserRole() {
      if (this.menu == true) this.changeEvent();
    },
    userMail() {
      this.changeEvent();
    },
  },
  components: {TextInput},

  methods: {
    changeEvent() {
      if (this.selectedUserRole == this.role) return;

      if (this.roleOnly == true)
        return this.$emit('update', {
          type: this.userTypes[this.selectedUserRole].type,
          id: this.userTypes[this.selectedUserRole].id,
        });

      if (this.$helper.isValidMail(this.userMail) == true) {
        this.$emit('update', {
          email: this.userMail,
          type: this.userTypes[this.selectedUserRole].type,
          id: this.userTypes[this.selectedUserRole].id,
        });
      }
    },
  },
};
</script>

<style scoped>
.role-selector {
  height: 40px;
  width: 200px;
  background-color: #f0f0f0;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 200ms linear;
}
.role-selector:hover {
  background-color: #e0e0e0;
  transition: background-color 200ms linear;
}
</style>
