<template>
  <div>
    <v-dialog v-model="dialog" :width="width" :persistent="!closeable">
      <template v-slot:activator="{ on, attrs }">
        <slot name="button" :on="on" :attrs="attrs" />
      </template>

      <DialogTemplate
        :closeable="closeable"
        v-on:close="dialog = false"
        :title="title"
        :icon="icon"
        :noHeader="noHeader"
        class="elevation-1"
      >
        <template v-slot:content>
          <div style="line-height: 1.3; font-size: 15px">
          <slot name="content"/>
        </div>
        </template>
        <template v-slot:actions>
          <slot name="actions" />
        </template>
      </DialogTemplate>
    </v-dialog>
  </div>
</template>

<script>
import DialogTemplate from "./DialogTemplate.vue";
export default {
  props: {
    icon: {
      type: String, 
      default: ''
    },
    color: {
      type: String,
      default: 'black'
    },
    opacity: {
      type: Number,
      default: .7
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      required: true,
      default: 400,
    },

    closeable: {
      type: Boolean,
      default: true,
    },

    close: {
      type: Boolean
    }
  },
  components: {
    DialogTemplate,
  },

  watch: {
    close: function() {
      if (this.close) return this.dialog = false
    },

    dialog: function() {
      if (!this.dialog) {
        setTimeout(() => {
          return this.$emit('closed')
        }, 200)

        } else {
          this.$emit('open')
        }
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
