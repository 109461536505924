<template>
  <div class="" style="position: relative; height: 100%; overflow: hidden; width: 100%">
    <div
      v-show="
        !(
          !isSettings &&
          $store.getters.presets != null &&
          $store.getters.presets.length == 0
        ) &&
        !hideHeader &&
        !error &&
        !loading
      "
      style="position: relative; width: 100%; height: 55px"
      class="ma-1 ml-2 mr-2 black--text"
    >
      <v-row
        class="ma-0 mr-0 ml-3 pt-1"
        :class="
          $vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly
            ? 'ml-15'
            : ''
        "
        align="center"
      >
        <!-- Title with primary underline -->
        <span style="position: relative">
          <span class="font-weight-bold header-title">{{ title }}</span>
          <div class="primary header-underline" />
        </span>
        <v-fade-transition>
          <div v-show="isSearching" class="ml-2 font-weight-light">
            <v-icon class="mt-1" size="25">mdi-magnify</v-icon>
          </div>
        </v-fade-transition>

        <slot name="header-additional" />

        <v-spacer />
        <div>
          <v-row class="ma-0 pr-5" align="center" style="height: 40px">
            <slot name="header-action" />

            <TextInput
              v-show="!$vuetify.breakpoint.xsOnly && searchable"
              style="width: 300px"
              class="ml-2"
              :clearable="true"
              v-on:change="searchInputEvent"
              placeholder="Suchen..."
              icon="mdi-magnify"
              :value="searchInputString"
            />
          </v-row>
        </div>
      </v-row>
    </div>

    <div
      v-if="
        !isSettings && (project.presets == null || project.presets.length == 0)
      "
      style="position: relative; height: 100%; width: 100%"
    >
      <div v-if="project.general.allowRecordings">
        <v-row
          style="
            position: absolute;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        >
          <v-icon color="grey" class="mr-2" size="18"
            >mdi-image-off-outline</v-icon
          >
          <span class="grey--text" style="font-size: 14px">{{
            $t('tools.project.dashboard.error.noImages')
          }}</span>
        </v-row>
      </div>
      <div v-else></div>
    </div>

    <div v-else-if="error == true">
      <NoDataTemplate
        class="center-component"
        :button="true"
        buttonIcon="mdi-refresh"
        buttonText="Neu laden"
        icon="mdi-alert-circle-outline"
        text="Fehler beim Laden"
        v-on:click="refreshPage"
      />
    </div>

    <div
      v-else-if="loading"
      style="position: relative; height: 100%;  width: 100%"
    >
      
      <LoadingProjectData class="center-component" :text="loadingText" />
    </div>

    <div v-else style="position: relative; height: 100%; width: 100%; overflow: hidden;" 
    :style="hideHeader ? 'height: 100%; ' : 'height: calc(100% - 60px)'">
   
    
          <slot name="content" />


      <v-fab-transition>
        <v-btn
          v-show="scrollPosY > 150"
          color="primary"
          fab
          dark
          small
          absolute
          bottom
          right
          :ripple="false"
          style="z-index: 1000000"
          class="mb-8"
          @click="toTop"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
import TextInput from '@components/text/TextInput.vue';

// import IconButton from '@components/button/IconButton.vue'
import NoDataTemplate from '@components/page/NoDataTemplate.vue';
import LoadingProjectData from '@components/loading/LoadingProjectData.vue';

// @group GroupA
export default {
  name: 'ProjectPageTemplate',
  props: {
    // loading text underneath loader
    loadingText: {
      type: String,
      default: '',
    },

    // hide 'no presets' page for settings
    isSettings: {
      type: Boolean,
    },

    // is Schedule
    isSchedule: {
      type: Boolean,
    },

    // page title
    title: {
      type: String,
    },

    // is Searchable (events)
    searchable: {
      type: Boolean,
      default: false,
    },

    // loading data
    loading: {
      type: Boolean,
      required: true,
    },

    // error
    error: {
      type: Boolean,
      required: true,
    },

    // hide Header (no title required)
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    project() {
      return this.$store.getters.project;
    },
    isShowBanner() {
      return this.$store.getters.getShowScheduleBanner;
    }
  },

  updated() {
    const elementHandle = document.getElementById('content-page');

    if (this.loading == false && this.error == false && elementHandle != null) {
      if (!!elementHandle.getAttribute('scroll-listener') != true) {
        document
          .getElementById('content-page')
          .addEventListener('scroll', this.handleScroll);
        document
          .getElementById('content-page')
          .setAttribute('scroll-listener', 'true');
      }
    }
  },

  beforeDestroy() {
    if (document.getElementById('content-page') != null) {
      document
        .getElementById('content-page')
        .removeEventListener('scroll', this.handleScroll);
      document
        .getElementById('content-page')
        .setAttribute('scroll-listener', null);
    }
  },
  data() {
    return {
      descendingSearch: true,
      searchInputString: '',
      isSearching: false,
      scTimer: 0,
      scrollPosY: 0,
    };
  },
  components: {
    TextInput,
    NoDataTemplate,
    // IconButton,
    LoadingProjectData,
  },

  watch: {
    clearSearch() {
      if (this.clearSearch == true) this.deleteSearch();
    },
  },

  methods: {
    deleteSearch() {
      this.searchInputString = '';
      this.isSearching = false;
      this.$emit('search', '');
    },
    searchInputEvent(searchString) {
      this.searchInputString = searchString;
      if (
        this.searchInputString != undefined &&
        this.searchInputString != null &&
        this.searchInputString.length > 0
      )
        this.isSearching = true;
      else this.isSearching = false;

      // event fires when search input changes
      // @arg search string
      this.$emit('search', searchString);
    },
    refreshPage() {
      window.location.reload();
    },
    handleScroll() {
      var doc = document.getElementById('content-page');

      // console.l('H', doc.scrollTop)
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scrollPosY = doc.scrollTop;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);

      // console.l(this.scrollPosY)
    },
    toTop: function () {
      var doc = document.getElementById('content-page');
      doc.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.header-title {
  font-size: 24px;
  padding-right: 1px;
}

.content-no-header {
    position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

.header-underline {
  border-radius: 2px;
  height: 4px;
  width: 100%;
  bottom: 0px;
  position: absolute;
}

.project-header {
  border-radius: 0px !important;
  height: 55px;
  border-bottom: 1px solid rgba(200, 200, 200, 1);
}

.center-component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
