var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","height":"100%","overflow":"hidden","width":"100%"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      !(
        !_vm.isSettings &&
        _vm.$store.getters.presets != null &&
        _vm.$store.getters.presets.length == 0
      ) &&
      !_vm.hideHeader &&
      !_vm.error &&
      !_vm.loading
    ),expression:"\n      !(\n        !isSettings &&\n        $store.getters.presets != null &&\n        $store.getters.presets.length == 0\n      ) &&\n      !hideHeader &&\n      !error &&\n      !loading\n    "}],staticClass:"ma-1 ml-2 mr-2 black--text",staticStyle:{"position":"relative","width":"100%","height":"55px"}},[_c('v-row',{staticClass:"ma-0 mr-0 ml-3 pt-1",class:_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly
          ? 'ml-15'
          : '',attrs:{"align":"center"}},[_c('span',{staticStyle:{"position":"relative"}},[_c('span',{staticClass:"font-weight-bold header-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"primary header-underline"})]),_c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearching),expression:"isSearching"}],staticClass:"ml-2 font-weight-light"},[_c('v-icon',{staticClass:"mt-1",attrs:{"size":"25"}},[_vm._v("mdi-magnify")])],1)]),_vm._t("header-additional"),_c('v-spacer'),_c('div',[_c('v-row',{staticClass:"ma-0 pr-5",staticStyle:{"height":"40px"},attrs:{"align":"center"}},[_vm._t("header-action"),_c('TextInput',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.xsOnly && _vm.searchable),expression:"!$vuetify.breakpoint.xsOnly && searchable"}],staticClass:"ml-2",staticStyle:{"width":"300px"},attrs:{"clearable":true,"placeholder":"Suchen...","icon":"mdi-magnify","value":_vm.searchInputString},on:{"change":_vm.searchInputEvent}})],2)],1)],2)],1),(
      !_vm.isSettings && (_vm.project.presets == null || _vm.project.presets.length == 0)
    )?_c('div',{staticStyle:{"position":"relative","height":"100%","width":"100%"}},[(_vm.project.general.allowRecordings)?_c('div',[_c('v-row',{staticStyle:{"position":"absolute","top":"48%","left":"50%","transform":"translate(-50%, -50%)"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"grey","size":"18"}},[_vm._v("mdi-image-off-outline")]),_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t('tools.project.dashboard.error.noImages')))])],1)],1):_c('div')]):(_vm.error == true)?_c('div',[_c('NoDataTemplate',{staticClass:"center-component",attrs:{"button":true,"buttonIcon":"mdi-refresh","buttonText":"Neu laden","icon":"mdi-alert-circle-outline","text":"Fehler beim Laden"},on:{"click":_vm.refreshPage}})],1):(_vm.loading)?_c('div',{staticStyle:{"position":"relative","height":"100%","width":"100%"}},[_c('LoadingProjectData',{staticClass:"center-component",attrs:{"text":_vm.loadingText}})],1):_c('div',{staticStyle:{"position":"relative","height":"100%","width":"100%","overflow":"hidden"},style:(_vm.hideHeader ? 'height: 100%; ' : 'height: calc(100% - 60px)')},[_vm._t("content"),_c('v-fab-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.scrollPosY > 150),expression:"scrollPosY > 150"}],staticClass:"mb-8",staticStyle:{"z-index":"1000000"},attrs:{"color":"primary","fab":"","dark":"","small":"","absolute":"","bottom":"","right":"","ripple":false},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }