<template>
  <ProjectPageTemplate
  style="position: relative; height: 100%; width: 100%;"

    :loading="false"
    :isSettings="true"
    :error="false"
    :title="$t(`${$route.meta.i18n}.title`)"
  >
    <template v-slot:header-action>
      <!--  -->
      <InviteUserProject v-show="isOwner" />

      <TextInput
        class="mr-2 ml-3"
        v-show="projectUser != null"
        style="width: 300px"
        clearable
        icon="mdi-magnify"
        :value="searchValue"
        v-on:change="searchValue = $event"
        :placeholder="`${$t('helper.text.search')}...`"
      />
    </template>

    <template v-slot:content>
        <div style="position: relative; height: 100%;">
      <div
        v-if="
          projectUser != null &&
          projectUser.length > 0 &&
          filteredProjectUser.length > 0
        "
        class="pb-3"
        style="
          height: 100%;
          position: relative;
          overflow-x: hidden;
          overflow-y: auto;
        "
      >
        <v-list
          :key="updateUserList"
          style="background-color: transparent"
          class="mr-5 ml-3"
        >
          <v-list-item
            style="border-radius: 10px !important"
            class="grey lighten-4 mt-1"
            v-for="(item, index) in filteredProjectUser"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>
                <div v-if="item.meta.verified">
                  <span v-if="item.meta.name">{{ item.meta.name }}</span>
                  <span v-else style="font-size: 14px" class="grey--text">{{
                    $t('tools.project.settings.user.noName')
                  }}</span>
                </div>
                <span v-else>
                  <v-row class="ma-0" align="center">
                    <v-icon class="mr-2" style="margin-top: 2px" size="18"
                      >mdi-email</v-icon
                    >
                    {{ item.meta.email }}
                  </v-row>
                </span>
              </v-list-item-title>

              <v-list-item-subtitle>
                <span v-if="item.meta.verified">{{ item.meta.email }}</span>

                <span v-else style="padding-left: 2px" class="ml-6">{{
                  $t('tools.project.settings.user.invitePending')
                }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-row class="ma-0" align="center">
                <IconButton 
                  v-show="!item.meta.verified && isOwner"
                  :loading="loadingInvite == item.userId"
                  v-on:click="resendUserInvite(item.userId)"
                  class="mr-2"
                  style="border-radius: 50%;border: 1px solid #E0E0E0;"
                  icon="mdi-email-send"
                  :tooltip="$t('tools.project.settings.user.dialog.resendInvite.button.resendInvite')"
                  />                  
                <UserProjectInviteInput
                  :key="item.role"
                  style="border-radius: 20px; border: 1px solid #d0d0d0"
                  v-on:update="updatedUserRole($event, item)"
                  :disabled="!isOwner || loadingRoleChange != null"
                  :loading="loadingRoleChange == item.userId"
                  :roleOnly="true"
                  :role="item.role"
                />

                <IconButton
                  v-show="isOwner"
                  :disabled="loadingRoleChange != null"
                  :loading="loadingUserRemoval == item.userId"
                  v-on:click="removeUserProject(item.userId)"
                  class="ml-2"
                  style="border-radius: 50%; border: 1px solid #e0e0e0"
                  icon="mdi-delete"
                />
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <div v-else>
        <v-row
          class="ml-5 mt-3 grey--text"
          align="center"
          justify="center"
          style="font-size: 14px"
        >
          {{ $t('tools.project.settings.user.noDataFound') }}</v-row
        >
      </div>
      </div>
    </template>
  </ProjectPageTemplate>
</template>

<script>
import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';

import TextInput from '../../../../../../ui-components/src/components/text/TextInput.vue';
import IconButton from '../../../../../../ui-components/src/components/button/IconButton.vue';
import UserProjectInviteInput from '../../../../../components/user/UserProjectInviteInput.vue';
import InviteUserProject from '../../../../../components/user/dialog/InviteUserProject.vue';

export default {
  components: {
    // TextButton,
    ProjectPageTemplate,
    TextInput,
    IconButton,
    UserProjectInviteInput,
    InviteUserProject,
  },

  data() {
    return {
      searchValue: '',
      loadingUserRemoval: false,
      loadingRoleChange: null,
      updateUserList: false,
      loadingInvite: false,
    };
  },

  watch: {
    projectUser() {
      this.updateUserList = !this.updateUserList;
    },
  },

  computed: {
    filteredProjectUser() {
      if (
        this.$route.params.projectId ==
          '99c43ce5-fb76-43a6-a00c-f4d836457e2c' ||
        this.projectUser == null ||
        this.projectUser.length == 0
      )
        return [];

      let userList = this.projectUser.filter(
        (user) =>
          user.meta.email
            .toUpperCase()
            .includes(this.searchValue.toUpperCase()) ||
          (user.meta.name || '')
            .toUpperCase()
            .includes(this.searchValue.toUpperCase()),
      );
      userList = userList.sort((a, b) => a.id - b.id);

      return userList;
    },
    projectUser() {
      return this.$store.getters.projectUsers || [];
    },

    isOwner() {
      return this.$auth.isProjectOwner();
    },
  },

  methods: {
    async updatedUserRole(eventHandle, user) {
      if (user.role != eventHandle.id) {
        this.loadingRoleChange = user.userId;
        try {
          await this.$api.project.invite(
            this.$route.params.projectId,
            user.userId,
            eventHandle.type,
          );
        } catch {
          this.$toast.error(
            this.$t('tools.project.settings.user.toasts.errorRoleUpdate'),
          );
          this.loadingRoleChange = null;
          return;
        }
        await this.getUsers();
        this.$toast.info(
          this.$t('tools.project.settings.user.toasts.successRoleUpdate'),
        );

        this.loadingRoleChange = null;
      }
    },
    async removeUserProject(userId) {
      this.loadingUserRemoval = userId;
      const resp = await this.$api.project.kick(
        this.$route.params.projectId,
        userId,
      );
      if (resp.success == false) {
        return this.$toast.error(
          this.$t('tools.project.settings.user.toasts.errorRemoval'),
        );
      }
      await this.getUsers();
      this.loadingUserRemoval = false;
      this.$toast.info(
        this.$t('tools.project.settings.user.toasts.successRemoval'),
      );
    },

    async resendUserInvite(id) {

      this.loadingInvite = id;
      
      const resp = await this.$api.user.resendInvite(id);

       if (resp.success) {
          this.loadingInvite = false;
          this.$toast.info(this.$t('tools.project.settings.user.dialog.resendInvite.toasts.resendInviteSuccess'))
        } else if(/last \d minutes/.test(resp.error.response.data.error)) {
          this.loadingInvite = false;
          this.$toast.error(this.$t('tools.project.settings.user.dialog.resendInvite.toasts.resendInviteError5Min'))
        } else {
          this.loadingInvite = false;
          this.$toast.error(this.$t('tools.project.settings.user.dialog.resendInvite.toasts.resendInviteError'))
        }
    },

    async getUsers() {
      try {
        await this.$api.project.information(this.$route.params.projectId);
        const project = this.$store.getters.project;
        if (project.general.members == null)
          return this.$store.commit('updateProjectUser', []);

        const resp = await Promise.all(
          project.general.members.map((user) => this.userData(user)),
        );

        const userHandle = this.$store.getters.user;

        if (userHandle != null && userHandle.id != null) {
          const userObj = resp.reduce((user) => user.id == userHandle.id);

          if (
            userHandle.internal != true &&
            userObj != null &&
            userObj.type == 10 &&
            this.$route.name != 'project_dashboard'
          ) {
            this.$router.push({name: 'project_dashboard'}).catch(() => {});
          }
        }

        this.$store.commit(
          'updateProjectUser',
          resp.filter((user) => user.meta != null),
        );
      } catch {
        return;
      }
    },

    async userData(userObj) {
      const user = await this.$api.user.user(userObj.userId);
      if (user.success == false || user.data == null) return userObj;
      else return {...userObj, meta: user.data};
    },
  },
};
</script>

<style scoped>
.embed-card {
  height: 150px;
  margin: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 7px;
  border: 2px solid #e0e0e0;
  position: relative;
  border-radius: 10px;
  background-color: #f0f0f0;
}
</style>
