var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('TextInput',{directives:[{name:"show",rawName:"v-show",value:(!_vm.roleOnly),expression:"!roleOnly"}],staticClass:"mr-2",attrs:{"value":_vm.userMail,"placeholder":_vm.$t('helper.userInvite.mail')},on:{"change":function($event){_vm.userMail = $event}}}),_c('div',{style:(_vm.disabled || _vm.loading ? 'pointer-events: none!important' : '')},[_c('v-menu',{attrs:{"disabled":_vm.disabled,"nudge-top":"2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"role-selector pl-2"},'div',attrs,false),on),[_c('v-hover',{attrs:{"disabled":_vm.disabled || _vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"ma-0",staticStyle:{"padding-top":"10px"},attrs:{"align":"center"}},[_c('span',{staticClass:"pl-5 font-weight-bold",staticStyle:{"font-size":"15px","user-select":"none","color":"rgba(0, 0, 0, 0.8)"}},[_vm._v(_vm._s(_vm.userTypes[_vm.selectedUserRole].descr))]),_c('v-spacer'),(_vm.loading)?_c('div',{staticStyle:{"height":"20px"}},[_c('v-progress-circular',{staticClass:"mr-4 mb-2",attrs:{"indeterminate":"","width":"2","size":"16","color":"primary"}})],1):_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],staticClass:"mr-3",class:hover ? 'primary--text' : '',attrs:{"size":"20"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)})],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{attrs:{"nav":"","width":"200"}},[_c('v-list-item-group',{model:{value:(_vm.selectedUserRole),callback:function ($$v) {_vm.selectedUserRole=$$v},expression:"selectedUserRole"}},_vm._l((_vm.userTypes),function(type,index){return _c('v-list-item',{key:index,style:(_vm.selectedUserRole == index
                  ? 'cursor: default!important; pointer-events: none!important'
                  : ''),attrs:{"dense":"","ripple":false}},[_c('v-list-item-title',{staticClass:"ml-1",class:_vm.selectedUserRole == index
                    ? 'primary--text font-weight-bold'
                    : '',staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(type.descr))])],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }