<template>
    <Dialog icon="mdi-account-plus" v-on:closed="closedDialogEvent" :close="closeEvent" :closable="!loadingInvite"
        :opacity="0.2" width="500" :title="$t('tools.project.settings.user.dialog.invite.title')">
        <template v-slot:button="{ on, attrs }">
            <div style="border-radius: 50%" v-bind="attrs" v-on="on"
                :style="disabled? 'user-select: none; pointer: default;':''">
                <IconButton icon="mdi-plus" tooltipPosition="l" :disabled="disabled"
                    :tooltip="$t('tools.project.settings.user.dialog.invite.title')"
                    style="border-radius: 50%; border: 1px solid #c0c0c0" />
            </div>
        </template>

        <template v-slot:content>
            <span style="font-size: 15px">{{
                $t('tools.project.settings.user.dialog.invite.text')
            }}</span>
            <UserProjectInviteInput :key="reset" class="mt-4 mb-5" v-on:update="updateInviteDataEvent" />
            <!-- <UserRightsSelector -->
        </template>

        <template v-slot:actions>
            <v-row class="ma-0" align="center">
                <v-spacer />

                <TextButton class="ml-2" :text="$t('tools.project.settings.user.dialog.invite.button.invite')"
                    :loading="loadingInvite" :disabled="user==null" v-on:click="inviteUser()" buttonColor="primary" />
            </v-row>
        </template>
    </Dialog>
</template>

<script>
import TextButton from '@components/button/TextButton.vue';

import Dialog from '@components/dialog/Dialog.vue';
import IconButton from '@components/button/IconButton.vue';
import UserProjectInviteInput from '../UserProjectInviteInput.vue';

export default {
    name: 'InviteUserProject',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TextButton,
        Dialog,
        IconButton,
        UserProjectInviteInput,
    },

    data() {
        return {
            reset: false,
            loadingInvite: false,
            user: null,
            closeEvent: false,
        };
    },

    methods: {
        closedDialogEvent() {
            this.closeEvent = true;

            this.$nextTick(() => {
                this.closeEvent = false;
            });
            setTimeout(() => {
                this.reset = !this.reset;
                this.loadingInvite = false;
                this.user = null;
            }, 200);
        },
        updateInviteDataEvent(eventHandle) {
            this.user = eventHandle;
        },

        async inviteUser() {
            this.loadingInvite = true;
            // 1. project id, role, email ---> egal ob existent
            // project member

            const resp = await this.$api.project.inviteByMail(
                this.$route.params.projectId,
                this.user.email,
                this.user.type,
            );

            if (resp.success == true) {
                await this.getUsers(this.$store.getters.project);
                this.closedDialogEvent();
            } else {
                this.$toast.error(
                    this.$t(
                        'tools.project.settings.user.dialog.invite.toasts.inviteError',
                    ),
                );
            }

            this.loadingInvite = false;
        },

        async getUsers(project) {
            try {
                await this.$api.project.information(this.$route.params.projectId);

                if (project.general.members == null)
                    return this.$store.commit('updateProjectUser', []);

                const resp = await Promise.all(
                    project.general.members.map((user) => this.userData(user)),
                );

                const userHandle = this.$store.getters.user;

                if (userHandle != null && userHandle.id != null) {
                    const userObj = resp.reduce((user) => user.id == userHandle.id);

                    if (
                        userHandle.internal != true &&
                        userObj != null &&
                        userObj.type == 10 &&
                        this.$route.name != 'project_dashboard'
                    ) {
                        this.$router.push({ name: 'project_dashboard' }).catch(() => { });
                    }
                }

                this.$store.commit(
                    'updateProjectUser',
                    resp.filter((user) => user.meta != null),
                );
            } catch {
                return;
            }
        },

        async userData(userObj) {
            const user = await this.$api.user.user(userObj.userId);
            if (user.success == false || user.data == null) return userObj;
            else return { ...userObj, meta: user.data };
        },
    },
};
</script>

<style scoped>
.v-application .v-autocomplete__content.menuable__content__active {
    max-width: 100px !important;
}
</style>
